<template>
  <section id="shop">
    <b-row class="mb-5">
      <b-col
        md="8"
        class="pb-3 pb-md-0"
      >
        <h1 v-if="!isEmpty(name)">
          {{ name }}
        </h1>
        <h1 v-else>
          {{ $t('shops') }}
        </h1>
        <h5
          v-if="isEmpty(vendorName)"
          class="text-primary"
        >
          {{ $t('sellerCategory') }}
        </h5>
        <h5
          v-else
          class="text-primary"
        >
          {{ vendorName }}
        </h5>
      </b-col>
    </b-row>
    <category-element
      :code="code"
      @resolve="resolve"
    />
  </section>
</template>

<script>
import {
  BRow,
  BCol,

} from 'bootstrap-vue'
import { isEmpty } from '@/store/functions'
import { getUserData } from '@/auth/utils'
import categoryElement from './CategoryElement.vue'

export default {
  components: {
    categoryElement,
    BRow,
    BCol,

  },

  data() {
    return {
      code: this.$route.params.code,
      isFinal: false,
      isEmpty,
      userData: getUserData(),
      vendor: '',
      vendorName: null,
      name: null,
      cat: [],
    }
  },
  mounted() {
    try {
      this.vendor = this.userData.profile.profileVendor.edges[0].node.id
    } catch (error) {
      this.vendor = ''
    }
  },
  methods: {
    resolve(data) {
      const [final, vendor, name] = data
      this.isFinal = final
      this.vendorName = vendor
      this.name = name
    },

  },
}
</script>
